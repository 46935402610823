// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faBars as fasFaBars } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faTimes as fasFaTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { faPhone as fasFaPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { faEnvelope as fasFaEnvelope } from "@fortawesome/pro-solid-svg-icons/faEnvelope";

/**
 * Add solid icons
 */
library.add(fasFaBars, fasFaTimes, fasFaPhone, fasFaEnvelope);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
